<template>
  <div>
    <base-header class="pb-7 pt-md-6 bg-gradient-dark"></base-header>
    <div class="order-xl-1">
      <div class="card">
        <div class="card-header">
          <b-row align-v="center" slot="header" >
            <b-col cols="8">
              <h2 class="mb-0"><i class="fas fa-money-bill-alt"></i> ข้อมูลสถานที่ตั้งราคา ณ ปัจจุบัน </h2>
            </b-col>
          </b-row>
        </div>

        <div class="card-body">
          <div class="card custom-card">
            <div class="card-body">
              <div class="table-responsive padding_top_20">
                <table class="table align-middle table-bordered">
                  <thead>
                      <tr class="text-center" >
                        <th>กลุ่มที่ตั้ง</th>
                        <th>สถานที่ตั้ง</th>
                        <th>ที่อยู่</th>
                        <th>วันที่อัพเดพทล่าสุด</th>
                        <th>ราคา ณ ปัจจุบัน</th>
                      </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(data) in items" :key="`supplier_mapping_preview_${data.id}`">
                      <td class="text-center">{{data.group_type=='location_group'?data.name:''}}</td>
                      <td class="text-center">{{data.group_type=='location'?data.name:''}}</td>
                      <td class="text-center">{{data.address}}</td>
                      <td class="text-center">{{toDate(data.active)}}</td>
                      <td class="text-right">{{data.cost | number-decimal-format}}</td>
                    </tr>
                    <tr v-if="items.length === 0" class="text-center">
                      <td colspan="4">ไม่มีข้อมูล</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!-- <div class="float-right mt-5">
                <b-button variant="secondary" @click="onBackHandler()"><i class="fa fa-arrow-left"></i> กลับ</b-button>
              </div> -->
            </div>
          </div>
          <div class="float-right mt-5">
            <span><router-link :to="'/supplier-mapping-location'" class="nav-link text-underline"> กลับ</router-link></span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import DateUtils from '../../../util/dateUtils';
  export default {
    name: 'supplier_mapping_location-view-page-location-preview',
    data() {
      return {
        permission:{
          appSlug: 'supplier-mapping-location',
          actionData: {}
        },
        items: []
      }
    },
    methods: {
      /************ Common ************/
      onBackHandler(){
        this.$router.push("/supplier-mapping-location");
      },

      async getItem(){
        const result = await this.HttpServices.getData(`/ab_supplier_location/getLastest/${this.id}`);
        if(result&&result.status.code=="200"){
          this.items = result.data;
        }else{
          this.AlertUtils.alertCallback('warning', `ไม่พบข้อมูลซัพพลายเออร์`, ()=>{
            this.$router.push('/supplier-mapping-location');
          });
        }
      },
      toDate(date){
        if(!date){
          return '';
        }

        return DateUtils.dateFormat(date, "DD/MM/YYYY HH:mm:ss")
      }
      /************ Common ************/
    },
    async mounted() {
      await this.PermissionServices.viewPermission(this.permission.appSlug, this.$router);
      this.permission.actionData = await this.PermissionServices.getPermission(this.permission.appSlug);
      this.id = await this.SessionStorageUtils.getSession("page-supplier-mapping-location");
      await this.getItem();
    }
  };
</script>
<style>

</style>
